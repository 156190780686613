<template>
  <LiefengContent>
    <template v-slot:title> 成员审核 </template>
    <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        :fixedmenu="true"
        @handleData="getList"
        treeID="mychild"
        fixedContentID="contentarea"
        style="width: 200px"
      ></LiefengTree>
    </template>
    <template v-slot:toolsbarRight>
      <Input
        :maxlength="20"
        enter-button
        v-model.trim="searchData.name"
        placeholder="姓名"
        style="width: 150px; margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Input
        :maxlength="20"
        enter-button
        v-model.trim="searchData.phone"
        placeholder="手机号"
        style="width: 150px; margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Button
        style="margin: 0 10px 0 0"
        type="primary"
        icon="ios-search"
        @click="getList"
        >查询</Button
      >
      <Button
        style="margin: 0 10px 0 0"
        type="success"
        icon="ios-refresh"
        @click="rest"
        >重置</Button
      >
      <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />

      <Drawer
        title="查询条件"
        v-model="selectQuery"
        width="320"
        :styles="{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: '53px',
          position: 'static',
        }"
      >
        <Form :model="searchData" :label-colon="true">
          <FormItem label="姓名">
            <Input
              v-model.trim="searchData.name"
              placeholder="请输入姓名"
              :maxlength="20"
            ></Input>
          </FormItem>
          <FormItem label="手机号">
            <Input
              :maxlength="20"
              v-model.trim="searchData.phone"
              placeholder="请输入手机号"
            ></Input>
          </FormItem>
          <FormItem label="详细地址">
            <Input
              :maxlength="20"
              v-model.trim="searchData.detailAddr"
              placeholder="请输入详细地址"
              style="width: 100%"
            />
          </FormItem>
          <FormItem label="家庭联络人">
            <Select
              v-model="searchData.contact"
              placeholder="请选择是否家庭联络人"
              clearable
            >
              <Option value="1">是</Option>
              <Option value="2">否</Option>
            </Select>
          </FormItem>
          <FormItem label="填写人姓名">
            <Input
              :maxlength="20"
              v-model.trim="searchData.fillerName"
              placeholder="请输入填写人姓名"
              style="width: 100%"
            />
          </FormItem>
        </Form>
        <div class="demo-drawer-footer">
          <Button style="margin-right: 8px" @click="selectQuery = false"
            >取消</Button
          >
          <Button type="primary" @click="hadlePageSize">查询</Button>
        </div>
      </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        height="200"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :curPage="curPage"
        :total="total"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
      <!-- 驳回框 -->
      <LiefengModal
        title="成员信息驳回"
        width="30%"
        :value="rejectModal"
        @input="rejectModalFn"
      >
        <template v-slot:contentarea>
          <Form
            ref="formValidate"
            :model="formValidate"
            :rules="ruleValidate"
            :label-width="80"
          >
            <FormItem label="驳回原因" prop="reason">
              <Input
                :maxlength="20"
                v-model.trim="formValidate.reason"
                placeholder="请输入驳回原因"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="rejectModal = false"
            style="margin-right: 8px"
            >取消</Button
          >
          <Button type="primary" @click="rejectEvent('formValidate')"
            >确认</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/memberindex")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengTree from "@/components/LiefengTree";
import LiefengModal from "@/components/LiefengModal";
export default {
  watch: {
    selectQuery(newV) {
      if (!newV) {
        this.searchData.phone = "";
        this.searchData.name = "";
        this.searchData.detailAddr = "";
        this.searchData.contact = "";
        this.searchData.fillerName = "";
      }
    },
  },
  data() {
    return {
      selectQuery: false,
      loading: false,
      //菜单树编码和名称
      orgCode: "",
      orgName: "",
      searchData: {
        name: "",
        phone: "",
        pageSize: 20,
        page: 1,
        detailAddr: '',
        contact: '',
        fillerName: ''
      },
      inviterFlag: 2, //1 居民 2 成员
      status: 1, //审核状态 1：未审核 2：已通过 3 驳回
      treeParams: {
        inviterFlag: 2, //1 居民 2 成员
        status: 1, //必填
        roadCode: "",
        projectCode: "",
        communityCode: "", //必填
        pageSize: 20,
        page: 1,
        name: "",
        phone: "",
        oemCode: "", //必填  缓存拿
      },
      treeData: [],
      talbeColumns: [
        {
          title: "姓名",
          key: "name",
          align: "center",
          width: 100,
        },
        {
          title: "性别",
          key: "sexLabel",
          align: "center",
          width: 80,
        },
        {
          title: "联系电话",
          key: "phone",
          width: 130,
          align: "center",
        },
        {
          title: "街巷",
          key: "roadName",
          align: "center",
          minWidth: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.roadName,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                    },
                  },
                },
                params.row.roadName
              ),
            ]);
          },
        },
        {
          title: "所在详细地址",
          key: "detailAddr",
          align: "center",
          minWidth: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.detailAddr,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                    },
                  },
                },
                params.row.detailAddr
              ),
            ]);
          },
        },
        {
          title: "是否家庭户代表",
          key: "contactLabel",
          align: "center",
          width: 140,
        },
        {
          title: "填写人",
          key: "fillerName",
          align: "center",
          width: 100,
        },
        {
          title: "操作",
          slot: "action",
          width: 190,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },

                  on: {
                    click: () => {
                      this.througth(params.row);
                    },
                  },
                },
                "通过"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    margin: "0 5px",
                  },
                  on: {
                    click: () => {
                      this.reject(params.row);
                    },
                  },
                },
                "驳回"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.remove(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      hidenBread: true,
      showDo: false,
      hideEdit: true,
      hideDel: true,
      hideSearch: false,
      pageSize: 20,
      curPage: 1,
      total: 0,
      rejectModal: false,
      rejectParams: {
        familyMemberId: "",
        reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
        name: parent.vue.loginInfo.userinfo.nickName,
        status: 3,
        result: "",
      },
      formValidate: {
        reason: "",
      },
      ruleValidate: {
        reason: [
          {
            required: true,
            message: "驳回原因不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "姓名",
            "性别",
            "联系电话",
            "街巷",
            "所在详细地址",
            "是否家庭户代表",
            "填写人",
          ];
          let filterVal = [
            "name",
            "sexLabel",
            "phone",
            "roadName",
            "detailAddr",
            "contactLabel",
            "fillerName",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "成员审核列表"
          );
        },
      });
    },
    //驳回框事件
    rejectModalFn(status) {
      this.rejectModal = status;
    },
    //
    rejectEvent(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.rejectParams.result = this.formValidate.reason;
          this.$post(
            "/syaa/pc/sy/user/residents/changeSyFamilyMember",
            this.rejectParams
          )
            .then((res) => {
              if (res.code == "200") {
                this.$Message.success("操作成功");
                let data = {
                  pageSize: 20,
                  page: this.curPage,
                };
                this.getList(data);
                this.rejectModal = false;
                this.formValidate.reason = "";
              } else {
                this.$Message.error("操作失败！");
              }
            })
            .catch((err) => {
              this.$Message.error("操作失败！");
            });
        }
      });
    },
    reject(data) {
      let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
      this.curPage = this.curPage > totalPage ? totalPage : this.curPage;
      this.curPage = this.curPage < 1 ? 1 : this.curPage;
      if (data) {
        this.rejectModal = true;
        this.rejectParams.familyMemberId = data.familyMemberId;
      }
    },
    // 删除
    remove(row) {
      // console.log(row)
      if (row) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认删除数据</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
            // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
            let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
            this.curPage = this.curPage > totalPage ? totalPage : this.curPage;
            this.curPage = this.curPage < 1 ? 1 : this.curPage;

            this.$post("/syaa/pc/sy/user/residents/delFamilyMember", {
              familyMemberId: row.familyMemberId,
            })
              .then((res) => {
                if (res.code === "200") {
                  this.$Message.success("删除成功");
                  let data = {
                    pageSize: 20,
                    page: this.curPage,
                  };
                  this.getList(data);
                } else {
                  this.$Message.error("删除失败！");
                }
              })
              .catch((err) => this.$Message.error("删除失败！"));
          },
        });
      } else {
        this.$Message.error("请先选择数据");
      }
    },
    //通过
    througth(data) {
      if (data) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "确认通过该数据？",
          onOk: () => {
            let params = {
              familyMemberId: data.familyMemberId,
              reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
              name: parent.vue.loginInfo.userinfo.nickName,
              status: 2,
            };
            let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
            this.curPage = this.curPage > totalPage ? totalPage : this.curPage;
            this.curPage = this.curPage < 1 ? 1 : this.curPage;
            this.$post(
              "/syaa/pc/sy/user/residents/changeSyFamilyMember",
              params
            )
              .then((res) => {
                if (res.code == "200") {
                  this.$Message.success("操作成功");
                  let data = {
                    pageSize: 20,
                    page: this.curPage,
                  };
                  this.getList(data);
                }
              })
              .catch((err) => {
                this.$Message.success("操作失败！");
              });
          },
        });
      }
    },
    //重置
    rest() {
      this.searchData.phone = "";
      this.searchData.name = "";
      this.searchData.detailAddr = "";
      this.searchData.contact = "";
      this.searchData.fillerName = "";
      this.curPage = 1;
      this.getList();
    },
    //page页面改变
    hadlePageSize(obj) {
      this.getList(obj);
    },
    //菜单树
    getList(data) {
      this.loading = true;
      if (data) {
        //第一次进来页面就会执行
        if (data.communityCode) {
          this.searchData.name = "";
          this.searchData.phone = "";
          this.orgCode = data.communityCode;
          this.orgName = data.orgName;
          this.tableData = [];
          this.treeParams = {
            ...data,
            status: this.status, //必填
            name: this.searchData.name,
            phone: this.searchData.phone,
            oemCode: parent.vue.loginInfo.userinfo.oemCode,
            page: data.page,
            pageSize: data.pageSize,
            inviterFlag: 2,
          };
          this.curPage = data.page;
          this.pageSize = data.pageSize;
        }
        if (data.page) {
          //切换页面时
          this.treeParams.inviterFlag = 2;
          this.curPage = data.page;
          this.pageSize = data.pageSize;
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
          this.treeParams.fillerName = this.searchData.fillerName;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
          this.treeParams.inviterFlag = 2;

          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
          this.treeParams.fillerName = this.searchData.fillerName;
          this.curPage = 1;
        }
      } else {
        //重置的时候执行
        this.treeParams = {
          communityCode: this.treeParams.communityCode,
          roadCode: this.treeParams.roadCode,
          status: 1, //必填
          name: this.searchData.name,
          phone: this.searchData.phone,
          detailAddr: this.searchData.detailAddr,
          contact: this.searchData.contact,
          fillerName: this.searchData.fillerName,
          oemCode: parent.vue.loginInfo.userinfo.oemCode,
          page: this.curPage,
          pageSize: this.pageSize,
          inviterFlag: 2, //1 居民 2 成员
        };
      }
      //请求页面表格数据
      this.$get(
        "/syaa/pc/sy/user/residents/selectSyFamilyMemberPage",
        this.treeParams
      )
        .then((res) => {
          if (res.code === "200") {
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.curPage = res.currentPage;
            this.selectQuery = false;
            this.searchData.phone = "";
            this.searchData.name = "";
            this.searchData.detailAddr = "";
            this.searchData.contact = "";
            this.searchData.fillerName = "";
            this.loading = false;
          } else {
            this.$message.error({
              background: true,
              content: "获取数据失败！",
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error({
            background: true,
            content: "获取数据失败！",
          });
          this.loading = false;
        });
    },
  },
  components: {
    LiefengTable,
    LiefengTree,
    LiefengContent,
    LiefengModal,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
    margin-bottom: 0;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>